import React from 'react';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import Alert from '@cloudhub-ux/core/Alert';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import { RemoteSelector } from '@cloudhub-ux/core/selectors';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';

const CopyDialog = ({ onClose, open, onSave }) => {
  const { postData, error, reset } = useAdminAxios({});
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const handleFormSubmit = async (values) => {
    try {
      console.log(values);
      const data = await postData({
        url: `/judging/judginglevel/copyall`,
        params: {
          SourceYear: values.SourceYear.id,
          DestinationYear: values.DestinationYear.id,
        },
      });
      if (data && data.levels) {
        reset();
        onSave();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      maxWidth="sm"
      minHeight={400}
      style={{ margin: 'auto', height: 200 }}
      onClose={onClose}
      open={open}
    >
      <DialogHeader>Copy Juging Levels</DialogHeader>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{}}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block
                paper
                margin={sizes.baseMargin}
                padding={sizes.padding}
                flex={false}
              >
                <Field
                  name="SourceYear"
                  label="Copy From"
                  style={{ flex: 1 }}
                  component={RemoteSelector}
                  url="/system/awardyear/filtersearch"
                  axiosinstance={adminaxiosinstance}
                  labelExtractor={(option) =>
                    option && option.Name ? option.Name : `${option}`
                  }
                  valueExtractor={(row) =>
                    row && row.id
                      ? {
                          id: row.id,
                          Name: row.Name,
                          _id: row._id,
                          _ref: row._id,
                        }
                      : row
                  }
                  required
                />
                <Field
                  name="DestinationYear"
                  label="Copy To"
                  style={{ flex: 1 }}
                  component={RemoteSelector}
                  url="/system/awardyear/filtersearch"
                  axiosinstance={adminaxiosinstance}
                  labelExtractor={(option) =>
                    option && option.Name ? option.Name : `${option}`
                  }
                  valueExtractor={(row) =>
                    row && row.id
                      ? {
                          id: row.id,
                          Name: row.Name,
                          _id: row._id,
                          _ref: row._id,
                        }
                      : row
                  }
                  required
                />

                {error && (
                  <Alert
                    error
                    message={error}
                    onClose={() => {
                      reset();
                    }}
                  />
                )}
              </Block>
            </DialogContent>
            <DialogActions>
              <Button contained color={colors.secondary} onClick={onClose}>
                Cancel
              </Button>
              <Button
                contained
                color={colors.primary}
                onClick={handleSubmit}
                disabled={pristine || submitting}
              >
                Copy
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};
export default CopyDialog;
